import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ClassfieldListPage } from './home/classfield/classfield-list/classfield-list.page';
import { ClassfieldDetailPage } from './home/classfield/classfield-detail/classfield-detail.page';

import { ClassfieldResolver } from './home/classfield/resolvers/classfield.resolver';
import { ClassfieldListResolver } from './home/classfield/resolvers/classfield-list.resolver';
import { ClassfieldDetailResolver } from './home/classfield/resolvers/classfield-detail.resolver';
import {LandingPageComponent} from './landing-page/landing-page.component';
import { CategoryHomePageComponent} from './home/categories/category-home-page.component';

const routes: Routes = [
  {
    path: '', component: LandingPageComponent, resolve: ClassfieldResolver
  },

  {
    path: 'categories', component: CategoryHomePageComponent
  },
  {
    path:         'main',
    component:    ClassfieldListPage,
    resolve:      { AdListResolver: ClassfieldListResolver, classfield: ClassfieldResolver }
  },
  {
    path:         'main',
    loadChildren: () => import('./_layout/layout.module').then(m => m.LayoutModule)
  },
  {
    path:         ':categorySlug',
    component:    ClassfieldListPage,
    resolve:      { AdListResolver: ClassfieldListResolver,  classfield: ClassfieldResolver }
  },
  {
    path:         'main',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
  },

  {
    path:         'oglas/:slug',
    component: ClassfieldDetailPage,
    resolve:      { ad: ClassfieldDetailResolver }
  },
  {
    path:         'fullscreen-image',
    loadChildren: () => import('./_shared/components/fullscreen-image/fullscreen-image.module').then(
      m => m.FullscreenImagePageModule)
  },
  {
    path:       '*',
    redirectTo: '',
    pathMatch:  'full'
  },
  {
    path: '**',
    redirectTo: 'greska/stranica-nije-pronadjena',
  }
];

@NgModule({
            imports: [
              RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
            ],
            exports: [RouterModule]
          })
export class AppRoutingModule {
}
