import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { SearchableSelectPopoverComponent } from '../searchable-select-popover/searchable-select-popover.component';
import { PopoverController } from '@ionic/angular';
import { ISearchable } from '../../../models/ISearchable';
import { UntypedFormControl } from '@angular/forms';
import {StripService} from '../../../services/strip.service';

@Component({
  selector: 'app-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
})
export class SearchableSelectComponent implements OnInit {

  @Input() pickedEntityId: number = null;
  @Input() entities: ISearchable[] = [];
  @Input() placeholder = 'Grad/Mesto';
  @Input() addStyleToBorder = false;
  @Input() resetEntity = new UntypedFormControl(false);

  pickedEntity: ISearchable | undefined = undefined;

  @Output() entityPicked: EventEmitter<number> = new EventEmitter<number>();

  constructor( private popoverController: PopoverController,
               private stripService: StripService ) { }

  ngOnInit() {

    this.pickedEntity = this.pickedEntityId ? this.entities.find(entity => entity.id === this.pickedEntityId) : undefined;
    this.subscribeOnReset();
  }


  subscribeOnReset(): void {

    this.resetEntity.valueChanges.subscribe(value => {
      if (value) {
      this.pickedEntity = undefined;
      }
    });
  }

  getSelectedEntityName(): string {

    return this.pickedEntity ? this.stripService.removeBold(this.pickedEntity.name) : this.placeholder;
  }

  async openSelect($event) {
    const popover = await this.popoverController.create({
      component: SearchableSelectPopoverComponent,
      componentProps: {
        entities: this.entities
      },
      cssClass: 'searchable-select',
      mode: 'ios',
      event: $event
    });

    await popover.present();

    popover.onDidDismiss().then((data) => {

      if (data.data?.entity) {
        this.pickedEntity = data.data.entity;
        this.entityPicked.emit(this.pickedEntity.id);
      }
    });
  }
}
