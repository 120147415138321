import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { Keyboard } from '@capacitor/keyboard';
import { select, State, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import {authUser, isLoggedIn, unreadChatsIds} from '../../../auth/auth.selectors';
import { AppState } from '../../../reducers';
import { User } from '../../models/User';
import { PlatformService } from '../../services/platform.service';
import { Subscription } from 'rxjs';
import { MenuController } from '@ionic/angular';

@Component({
  selector:    'app-header',
  templateUrl: './header.component.html',
  styleUrls:   ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  loggedUser;
  authUser: User = null;
  unreadChats = [];
  url;
  darkMode = false;
  admin: boolean = false;

  @Input() searchWord: string = null;
  @Input() addNewAd           = false;

  @Output() addNewAdEmmiter: EventEmitter<void>    = new EventEmitter();
  @Output() reloadPageEmmiter: EventEmitter<void>  = new EventEmitter();
  @Output() searchWihKeyword: EventEmitter<string> = new EventEmitter<string>();
  @Output() onThemeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];

  constructor(public platformService: PlatformService,
              private store: Store<AppState>,
              private router: Router,
              private changeDetectorRef: ChangeDetectorRef,
              private menuController: MenuController) {
  }

  ngOnInit() {

    this.admin = localStorage.getItem('role')?.includes('admin') ? true : false;
    this.checkForLoginUser();
    this.url = this.router.url;
    this.darkMode = localStorage.getItem('theme') === 'dark' ? true : false;
    this.toggleTheme(this.darkMode);
  }

  checkForLoginUser(): void {

    this.store
      .pipe(select(isLoggedIn))
      .subscribe(response => {
        this.loggedUser = response;
        this.getAuthenticatedUser();
      });
  }

  /**
   * Fetch authenticated user data from store.
   *
   * @return void
   */
  getAuthenticatedUser(): void {

    this.store
      .pipe(select(authUser))
      .pipe(filter(user => !!user))
      .subscribe(response => {
        this.authUser = response;
        this.fetchUnreadChats();
      });
  }

  fetchUnreadChats(): void {

      this.store
        .pipe(select(unreadChatsIds))
        .subscribe(unreadChats => {
          this.unreadChats = unreadChats;
          this.changeDetectorRef.detectChanges();
        });
  }

  search(event) {

    this.searchWihKeyword.emit(event.detail?.value ? event.detail.value : null);
    Keyboard.hide();
  }

  handleNavigate() {

    if ( this.addNewAd ) {
      this.addNewAdEmmiter.emit();
    } else {
      this.loggedUser ? this.router.navigate(['/home/oglasi/dodaj-oglas']) : this.router.navigate(['/auth/prijava'], {queryParams: {navigate_to: '/home/oglasi/dodaj-oglas'}, replaceUrl: true });
    }
  }

  navigateToHome() {

    if ( this.router.url !== '/' ){
      this.router.navigate(['/'], {replaceUrl: true});
    }

    this.reloadPageEmmiter.emit();
  }

  navigateToInbox() {

    this.router.navigate(['/home/inbox'], {replaceUrl: true});
  }

  ngOnDestroy(): void {

    this.subscriptions.map(s => s.unsubscribe());
    this.subscriptions = [];
  }

  openSideMenu() {
    //this.menuController.enable(true, 'first');
    this.menuController.open().then();
  }

  toggleTheme( dark: boolean ){

    this.darkMode = dark;
    document.body.setAttribute('color-theme', this.darkMode ? 'dark' : 'light');
    localStorage.setItem('theme', this.darkMode ? 'dark' : 'light');

    this.onThemeChange.emit(this.darkMode);
  }


}
