import { Component, OnInit } from '@angular/core';
import {HomepageCategory} from '../../_shared/models/HomepageCategory';

@Component({
  selector: 'app-categories',
  templateUrl: './category-home-page.component.html',
  styleUrls: ['./category-home-page.component.scss'],
})
export class CategoryHomePageComponent implements OnInit {

  categories1: HomepageCategory[] = [
    { name: 'Restorani', iconPath: 'assets/icon/restaurant-icon.svg', route: '/restorani' },
    { name: 'Fotografi', iconPath: 'assets/icon/photography-icon.svg', route: '/fotografi' },
    { name: 'Vikendice', iconPath: 'assets/icon/cottage-icon.svg', route: '/vikendice'},
    { name: 'Rođendaonice', iconPath: 'assets/icon/birthday-icon.svg', route: '/rodjendaonice'},
    { name: 'Cvećare', iconPath: 'assets/icon/flower-icon.svg', route: '/cvecare'}
  ];

  categories2: HomepageCategory[] = [
    { name: 'Hoteli', iconPath: 'assets/icon/hotel-icon.svg', route: '/hoteli' },
    { name: 'Venčanice', iconPath: 'assets/icon/wedding-icon.svg', route: '/vencanice'},
    { name: 'Muzika', iconPath: 'assets/icon/music-icon.svg', route: '/muzika' },
    { name: 'Poslovni prostori', iconPath: 'assets/icon/office-icon.svg', route: '/poslovni-prostori' }
  ];

  categories3: HomepageCategory[] = [
    { name: 'Saloni lepote', iconPath: 'assets/icon/beauty-icon.svg', route: '/saloni-lepote' },
    { name: 'Torte i slatkiši', iconPath: 'assets/icon/sweets-icon.svg', route: '/torete-i-slatkisi'},
    { name: 'Dekoracija', iconPath: 'assets/icon/decoration-icon.svg', route: '/dekoracija' },
    { name: 'Zlatare', iconPath: 'assets/icon/jewelry-icon.svg', route: '/zlatare' },
    { name: 'Animatori', iconPath: 'assets/icon/animator-icon.svg', route: '/animatori' }
  ];

  constructor() { }

  ngOnInit() {}



}
